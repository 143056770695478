@import '../utils/core-utils';
@import url("https://fonts.googleapis.com/css?family=Bevan");
@import url("https://fonts.googleapis.com/css?family=Carrois+Gothic+SC|Six+Caps");
@import url("https://fonts.googleapis.com/css?family=Oswald");
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');

////GENERAL STYLING////

.App {
  text-align: center;
}

.menu {
  @include flex-row;
}

