.card-wrapper-loading {
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    z-index: 1;
    opacity: .7;
  }
  
  .card-loading-text {
    color: white;
    z-index: 2;
    padding-left: 10px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
  }
  
  .card-parent {
    padding: 60px 30px 30px 30px;
    margin: 0 -15px;
    display: flex;
    flex-direction: 100%;
    flex-direction: column;
    justify-content: center;
  }
  
  @media screen and (min-width: 600px) {
    .card-parent {
      padding: 60px 40px 40px 40px;
    }
  }
  
  .card-grid-wrapper {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: 
    'header'
    'first' 
    'side' 
    'right'
    'left' 
    'footer' 
    'last'
    ;
    column-gap: 10px;
    row-gap: 10px;
  }
  
  
  @media screen and (min-width: 768px) {
    .card-grid-wrapper {
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: repeat(4, 1fr);
      grid-template-areas: 
      'header header header header'
      'first first side right'
      'left footer last last'
      ;
      column-gap: 10px;
      row-gap: 10px;
    }
  }
  
  .card-main-wrapper {
    border-radius: 5px;
    grid-area: header;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url('../../assets/images/boat_sunset.jpeg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 300px;

    & .card-desc {
      position: unset;
    }
  }

  @media screen and (min-width: 768px) {
    .card-main-wrapper {
      height: 400px;
    }
  }
  
  .card-main-wrapper .card-country-name {
    margin-top: 50px;
  }
  
  .card-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    border-radius: 5px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 200px;
    justify-content: center;
  }
  
  @media screen and (min-width: 768px) {
    .card-wrapper {
      height: 250px;
    }
  }
  
  .card-wrapper:after {
    content: " ";
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 5px;
  }
  
  .card-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .3s ease;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 20;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .card-overlay .card-country-name,
  .card-overlay .card-city-name,
  .card-overlay .card-desc {
    z-index: 15;
  }
  
  .card-overlay .card-city-name {
    margin-bottom: 15px;
  }
  
  .card-overlay .card-desc {
    font-size: 10px;
    text-align: center;
    margin: 0 auto 30px auto;
    line-height: 15px;
    font-family: Helvetica Neue, serif;
    position: unset;
  }
  
  @include breakpoint-large {
    .card-overlay .card-desc {
      font-size: 12px;
    }
  }
  
  .card-overlay .card-explore-more {
    bottom: 15px;
  }
  
  .card-wrapper:hover .card-overlay {
    opacity: 1;
  }
  
  .card-wrapper:hover .card-country-name,
  .card-wrapper:hover .card-city-name {
    opacity: 0;
  }
  
  .card-wrapper:hover .card-overlay .card-country-name,
  .card-wrapper:hover .card-overlay .card-city-name {
    opacity: 1;
  }
  
  .card-country-name {
    align-self: center;
    z-index: 10;
    color: white;
    font-size: 8px;
    margin-bottom: 5px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: Helvetica Neue, serif;
  }
  
  @media screen and (min-width: 768px) {
    .card-country-name {
      font-size: 10px;
    }
  }
  
  .card-city-name {
    z-index: 10;
    color: white;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 11px;
    font-family: $roboto-condensed;
  }
  
  @media screen and (min-width: 768px) {
    .card-city-name {
      font-size: 16px;
    }
  }
  
  .card-desc {
    font-size: 10px;
    color: white;
    text-align: center;
    width: 75%;
    line-height: 18px; 
  }
  
  @media screen and (min-width: 768px) {
    .card-desc {
      font-size: 12px;
    }
  }  
  
  [data-index='0'] {
    grid-area: left;
    background: url('../../assets/images/paris.jpeg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
  
  [data-index='1'] {
    grid-area: side;
    background-image: url('../../assets/images/berlin.jpeg');
  }
  
  [data-index='2'] {
    grid-area: first;
    background-image: url('../../assets/images/venice.jpeg')
  }
  
  [data-index='3'] {
    grid-area: footer;
    background-image: url('../../assets/images/amsterdam.jpeg')
  }
  
  [data-index='4'] {
    grid-area: right;
    background-image: url('../../assets/images/barcelona.jpeg')
  }
  
  [data-index='5'] {
    grid-area: last;
    background-image: url('../../assets/images/london.jpeg')
  }
  
  .header-card {
    margin: 8px 0 5px 0;
    font-weight: 800;
    font-size: 2em;
    color: white;
  }
  
  @media screen and (min-width: 768px) {
    .header-card {
    //   font-size: 40px;
    }
  }
  
  .card-explore-more {
    position: absolute;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    bottom: 20px;
    color: white;
    background: transparent;
    border: 1px solid white;
    border-radius: 5%;
    font-size: 8px;
    white-space: nowrap;
    height: 20px;
    cursor: pointer;
  }

  .card-explore-more:focus {
    border: .5px solid blue;
  }