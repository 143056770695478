.greeting {
    padding-top: 40px;
    height: 80px;
    width: 100%;
    font-family: "Roboto", sans-serif;
    font-size: 26px;
    letter-spacing: 2px;
    text-align: center;
    position: relative;

    @include breakpoint-large {
        font-size: 42px;
        height: 60px;
    }
}

.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__intro-mobile {
        margin: 40px 0 60px 0;
        font-family: $roboto-condensed;
        font-weight: 400;
        font-size: 26px;
        letter-spacing: 3px;
        position: relative;
        text-align: center;

        &:after {
            position: absolute;
            content: '';
            height: 3px;
            bottom: -6px;
            margin: 0 auto;
            left: 0;
            right: 0;
            width: 10%;
            background: rgba(141, 141, 141, 0.3);

            -o-transition: .5s;
            -ms-transition: .5s;
            -moz-transition: .5s;
            -webkit-transition: .5s;
            transition: .5s;
        }

        &:hover:after {
            width: 20%;
        }

        &:hover {
            cursor: pointer;
        }

        @media screen and (min-width: 960px) {
            display: none;
        }
    }

    &__intro-desktop {
        display: none;

        @media screen and (min-width: 960px) {
            display: block;
            font-size: 35px;
            text-align: none;
            flex-basis: 50%;

            margin: 40px 0 60px 0;
            font-family: $roboto-condensed;
            font-weight: 400;
            letter-spacing: 3px;
            position: relative;
            text-align: center;

            &:after {
                width: 45%;
                position: absolute;
                content: '';
                height: 3px;
                bottom: 0;
                top: 46px;

                height: 3px;
                bottom: -6px;
                margin: 0 auto;
                left: 0;
                right: 0;
                width: 45%;
                background: rgba(141, 141, 141, 0.3);

                -o-transition: .5s;
                -ms-transition: .5s;
                -moz-transition: .5s;
                -webkit-transition: .5s;
                transition: .5s;
            }

            &:hover:after {
                width: 100%;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }

    &__welcome-info {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-row-gap: 0;
        row-gap: 0;
        height: 100vh;
        

        @include breakpoint-large {
            grid-template-columns: repeat(2, 1fr);
            padding: 20px 20px 20px 20px;
        }
    }

    &__name-wrapper {
        flex-basis: 50%;
        position: relative;
    }

    &__name {
        width: 100%;
        font-family: $roboto-font;
        position: absolute;
        top: 50%;
        color: $base-white-color;
        font-size: 28px;
        letter-spacing: 1px;
        text-align: center;
        transition: all .5s ease-in-out;

        &:hover {
            cursor: pointer;
            font-size: 60px;
        }

        @include breakpoint-medium {
            font-size: 42px;

            &:hover {
                cursor: pointer;
                font-size: 80px;
            }

        }
    }

    &__tagline {
        font-family: $roboto-condensed;
        background-color: $base-background-color;
        height: 300px;
        position: relative;
        width: 100%;
        letter-spacing: 1px;
        margin-top: -5px;

        @media screen and (min-width: 960px) {
            flex-basis: 50%;
            height: auto;
            margin-top: 0;
        }
    }

    &__job-type {
        position: absolute;
        top: 25%;
        text-align: center;
        width: 100%;
        padding: 0 5px;
        font-weight: 900;
        font-size: 2em;
        transition: all .5s ease-in-out;
        font-family: Helvetica Neue, serif;

        &:hover {
            cursor: pointer;
            font-size: 2.5em;
            color: $base-color-red;
        }

        @include breakpoint-medium {
            top: 20%;
            font-size: 2.5em;

            &:hover {
              font-size: 3em;
            }
        }

        @include breakpoint-large {
            top: 32%;
        }
    }

    &__job-location {
        position: absolute;
        top: 65%;
        width: 100%;
        text-align: center;
        transition: all .5s ease-in-out;
        margin-top: 5px;
        font-size: 1em;

        &:hover {
            cursor: pointer;
            font-size: 1.5em;
            color: $base-color-red;
        }

        @include breakpoint-medium {
          font-size: 1.5em ;
          top: 74%;

          &:hover {
            font-size: 2em;
          }
        }

        @include breakpoint-large {
            top: 60%;

        }
    }

    &__divider {
        height: 60px;

        &:after {
            content: '';
            border-right: 2px solid black;
            margin-right: 6px;
        }
    }

    &__image {
        height: 600px;
        width: 100%;
        object-fit: cover;

        @include breakpoint-large {
            height: 100%;
            width: 100%;
        }
    }
}