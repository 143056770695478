.carousel {
  width: 100vw;
  margin-left: calc(50% - 50vw);
}

.carousel-indicators [data-bs-target] {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin-right: 20px;
    margin-left: 20px;
    opacity: 1;
    background-color: $base-color-white;
}

.carousel-indicators {
  & .active {
    background-color: $base-color-black;
  }
}
