///// ABOUT PAGE /////

.about-wrapper {
    flex: 1 1 0;

    @include breakpoint-large {
      padding: 20px 20px 20px 20px;
    }
}

.about-title {
    @include straight-row;
    align-items: center;
    font-weight: 600;
    font-size: 3.5em;
    letter-spacing: 15px;
    font-family: $roboto-condensed;
    color: $base-red-color;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);

    // @include breakpoint-medium {
    // }
  }
  
  .about-content {
    @include straight-column;
    justify-content: center;
    align-items: center;
    background: $base-background-color;
  
    @include breakpoint-large {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

  }
  
  .about-image-wrapper {
      position: relative;

      @include breakpoint-large {

      }
  }

  .profile-img {
      height: 100%;
      object-fit: cover;
      width: 100vw;
      margin-left: calc(50% - 50vw);
  
      @include breakpoint-large {
        padding-top: 0;
        width: 100%;
        margin-left: 0;

      }
    }

    .about-para-wrapper {
        text-align: center;
        padding: 20px 40px;

        @include breakpoint-large {
            padding: 20px 60px;
        }
    }
  
    .about-para {
      font-family: Helvetica Neue, serif;
      letter-spacing: 2px;
      font-size: 14px;
      line-height: 1.35;
      font-weight: lighter;
      padding: 20px 0;
      text-align: center;
      margin: 0 20px 0 20px;
      flex: 1 1 0;
  
      @include breakpoint-medium {
        padding: 0;
        font-size: 20px;
      }
  
      &:hover {
        color: $base-red-color;
      }
    }

  .highlight {
    font-size: 36px;
    font-weight: 400;
    // text-transform: uppercase;

    @include breakpoint-medium {
        font-size: 46px;
    }
  }