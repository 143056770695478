body {
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  font-size: 16px;
    
  @media screen and (min-width: 900px) {
    font-size: 18px;
  }
    
  @media screen and (min-width: 1200px) {
    font-size: 20px;
  }
}

html, body {
  height: 100%;
  margin: 0;
}
