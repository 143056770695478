.carousel-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 650px;
    width: 100%;

    .carousel-slider {
      font-size: 10em;
      width: 100%;
      height: 195px;
      display: flex;
      margin: 0 30px 0 20px;
      overflow: hidden;

      .slide {
        width: auto;
        flex-shrink: 0;
        height: 100%;
        margin-right: 30px;
        margin-left: 10px;
      }
      
      .text-focus-in {
        // -webkit-animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53)
        //   both;
        animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
      }
      .jello-horizontal {
        // -webkit-animation: jello-horizontal 2s both;
        animation: jello-horizontal 2s both;
      }
      .vibrate-1 {
        // -webkit-animation: vibrate-1 0.3s linear infinite both;
        animation: vibrate-1 0.3s linear infinite both;
      }
    }
  }

  /* ----------------------------------------------
   * Generated by Animista
   * w: http://animista.net, t: @cssanimista
   * ---------------------------------------------- */
  /**
   * ----------------------------------------
   * animation text-focus-in
   * ----------------------------------------
   */

  @include text-focus() {
    0% {
      -webkit-filter: blur(12px);
      filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
  }
  
  /**
   * ----------------------------------------
   * animation jello-horizontal
   * ----------------------------------------
   */
  
  @include jello-focus() {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  
  /**
   * ----------------------------------------
   * animation vibrate-1
   * ----------------------------------------
   */
  
  @include vibrate-focus() {
    0% {
      -webkit-transform: translate(0);
      transform: translate(0);
    }
    20% {
      -webkit-transform: translate(-2px, 2px);
      transform: translate(-2px, 2px);
    }
    40% {
      -webkit-transform: translate(-2px, -2px);
      transform: translate(-2px, -2px);
    }
    60% {
      -webkit-transform: translate(2px, 2px);
      transform: translate(2px, 2px);
    }
    80% {
      -webkit-transform: translate(2px, -2px);
      transform: translate(2px, -2px);
    }
    100% {
      -webkit-transform: translate(0);
      transform: translate(0);
    }
  }

  @media only screen and (max-width: 800px) {
    .carousel-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 350px;
      width: 100%;

      .carousel-slider {
        font-size: 10em;
        width: 75%;
        height: 195px;
        display: flex;
        margin: 0 30px 0 30px;
        overflow: hidden;

        // &:hover {
        //   cursor: url('../../assets/images/scroll-horizontal-icon.png'), auto;
        // }

        .slide {
          width: auto;
          flex-shrink: 0;
          height: 100%;
          margin-right: 30px;
          margin-left: 10px;
        }
      }
    }
  }
  