// .vertical-text-bar {
//     position: absolute;
//     bottom: 0px;
//     top: 25%;
//     left: 50%;
//     white-space: nowrap;
//     transform: translate(-50%, -50%);
//     height: 10px;
//     display: block;

//     @include breakpoint-medium {
//       display: none;
//     }
// }

.dates {
  margin: 0 0 40px 0;

  @include breakpoint-medium {
    margin: 0 0 40px 50px;
  }
}

.bottom-nav {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    background-color: #fff;
    padding-bottom: 20px;
    align-items: center;

    .festival-info {
        display: block;

        @include breakpoint-medium {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: space-between;
        }
    }

    .festival-topics {
        margin-left: 0;
        font-size: 1.5em;
        margin-bottom: 30px;
        text-align: center;

        @include breakpoint-medium {
            margin-bottom: 0;
            font-size: 2em;
            margin-left: 50px;
            flex-basis: 50%;
            text-align: left;
        }
    }

    .festival-cities {
        margin-right: 0;
        font-size: 1.25em;
        text-align: center;

        @include breakpoint-medium {
            font-size: 2em;
            margin-right: 35px;
            flex-basis: 50%;
            text-align: right;
        }
    }
}