.l-main-content-insider {
    padding: 60px 20px 60px 20px;
}

.headline-bold.intro {
    padding-left: 0;
    font-size: 3.5em;

    @include breakpoint-medium {
        padding-left: 40px;
    }
}

.insider {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    height: 100vh;
    background-color: $base-white-color;
    row-gap: 15px;
    margin-top: 20px;

    @include breakpoint-medium {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      row-gap: 0;
    }
}

.insider-item {
    position: relative;


    &:hover {
        cursor: pointer;

        & .insider-overlay.card-overlay {
            opacity: 0;
            z-index: 1;
            transform: scale(.5);
            transition: all .2s ease-in-out
        }

        & .insider-video {
            opacity: 1;
            z-index: 2;
            transition: all .2s ease-in-out
        }
    }
}

.insider-video {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
}

.insider-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.insider-overlay.card-overlay {
    opacity: 1;
    z-index: 2;


    &:after {
        content: '';
        background-image: linear-gradient(
            rgba(0, 0, 0, 0.2),
            rgba(0, 0, 0, 0.2)
            );
            position: absolute;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            // border-radius: 5px;
        }
}

.insider-title {
    color: $base-white-color;
    position: absolute;
    bottom: 10%;
    left: 5%;
    background-color: transparent;
    z-index: 1;
}

.intro-subtext {
    color: $base-color-black;
}