.l-masthead {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
}

.masthead-nav-wrapper {
  justify-content: center;
  padding-left: 0;
  margin-bottom: 0;
}

.masthead-link {
  list-style: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding-right: 30px;

  &.home-link {
    font-size: 24px;
    line-height: 29.3px;
    font-weight: 500;
    text-align: center;
    padding: 30px 0;
  }
}

.buy-button {
  height: 46px;
  width: 100px;
  top: 30px;
  border-radius: 150px;
  background: $base-color-red;
  padding: 0;
  border: none;
}

.button-text {
  color: $base-color-white;
  font-size: 16px;
}
