.top-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: white;
    border-bottom: 2px solid rgba(141, 141, 141, 0.1);
    z-index: 10;

    &__name {
        // padding-top: 16px;
        height: 60px;
        width: 100%;
        font-family: "Roboto", sans-serif;
        font-size: 20px;
        letter-spacing: 2px;
        text-align: center;
        position: relative;
        margin-top: 16px;

        &:before {
            content: " ";
            display: block;
            height: 3px;
            width: 10%;
            position: absolute;
            top: 20%;
            left: 64px;
            background: red;
        }

        &:after {
            content: " ";
            height: 3px;
            width: 10%;
            background: red;
            display: block;
            position: absolute;
            top: 20%;
            right: 64px;
        }

        @media screen and (min-width: 600px) {
            font-size: 30px;

            &:before {
                // width: 10%;
                top: 28%;
                left: 20%;
            }

            &:after {
                // width: 10%;
                top: 28%;
                right: 20%;
            }

        }


    }
}

.App-header {
    min-height: 18vh;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: black;
    @include straight-column;
}

.general-btn-container {
    @include flex-row;
    height: 100px;

    .site-btn {
        @include animation-button;
      }
}