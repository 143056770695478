.l-main-content-ecommerce {
  border-top: $base-color-lt-gray;
  overflow-x: hidden;
}

.lead-content {
  position: absolute;
  top: 10%;
  z-index: 1;
  left: 0;
  right: 0;
}

.lead-image-container {
  width: 100%;
  height: 100%;
}

.lead-image-wrapper {
  width: 100%;
  position: relative;
}

.lead-image-inner-wrapper {
  position: relative;
}

.lead-image-mobile {
  width: 100vw;
  margin-left: calc(50% - 50vw);
}

.lead-image {
  width: 100vw;
  margin-left: calc(50% - 50vw);
}

.subtitle {
  color: $base-color-red;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  height: 48px;
  width: 233px;
  margin-bottom: 30px;
}

.caption {
  text-align: center;
  font-size: 30px;
  font-weight: 500;
  line-height: 35px;

  &.monitor-caption {
    text-align: left;
    margin-bottom: 30px;
    @media screen and (min-width: 992px) {
      font-size: 46px;
      line-height: 60px;
    }
    @media screen and (min-width: 1200px) {
      font-size: 52px;
    }
  }
}

.sticker-icon-wrapper {
  height: 0;
  position: absolute;
  transform: rotate(29.91deg);
  bottom: 124px;
  right: -50px;

  @media screen and (min-width: 768px) {
    right: auto;
    margin-top: 42%;
    margin-left: 80%;

    margin-right: 20%;
    margin-bottom: 58%;
  }
}

.sticker-icon {
  height: 189.06px;
  width: 189.06px;

  @media screen and (min-width: 768px) {
    height: 227.24px;
    width: 227.24px;
  }
}

.sticker-text {
  position: absolute;
  width: 150px;
  right: 20px;
  bottom: -134px;

  @media screen and (min-width: 768px) {
    left: 40px;
    top: 60px;
  }
}

.text-offer {
  font-size: 22px;
  font-weight: 500;
  line-height: 34px;
  color: $base-color-white;
}

.text-price {
  font-size: 30px;
  font-weight: 500;
  line-height: 35px;
  text-align: center;
  color: $base-color-white;
}

.text-second-price {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: $base-color-white;
  white-space: nowrap;
}

.buy-wrapper {
  margin-top: 60px;
}

.desc {
  font-size: 22px;
  font-weight: 500;
  line-height: 34px;
  text-align: center;
  margin-bottom: 30px;
  width: auto;

  @media screen and (min-width: 992px) {
    width: 813px;
  }
}

.buy-now {
  &.buy-button {
    height: 58px;
    width: 140px;
    left: 97.5px;
    top: 336px;
    padding: 16px 30px 20px 30px;
    border: none;
    margin-bottom: 60px;
  }
}

.monitor {
  width: 100vw;
  margin-left: calc(50% - 50vw);
}

.monitor-image-wrapper {
  @media screen and (min-width: 992px) {
    width: 50%;
  }
}

.monitor-image {
  width: 100vw;
  margin-left: calc(50% - 50vw);

  @media screen and (min-width: 992px) {
    width: 100%;
    margin-left: 0;
  }
}

.monitor-text {
  padding: 0 20px;
  margin-bottom: 60px;

  @media screen and (min-width: 992px) {
    padding: 0;
    margin-bottom: 0;
    align-items: center;
    width: 50%;
  }
}

.monitor-text-wrapper {
  width: auto;

  @media screen and (min-width: 992px) {
    width: 75%;
  }
}

.lock {
  margin: 30px 0;

  & img {
    height: 45px;
    width: 43px;
  }
}

.subtext {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  @media screen and (min-width: 992px) {
    font-size: 22px;
    line-height: 34px;
  }
}
