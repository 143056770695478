.modal {
  border-radius: 4px;
}

.modal-header {
  border-bottom: none !important;
}

.modal-icon {
  height: 73px;
  width: 73px;
  margin-bottom: 25px;
}

.modal-intro {
  font-size: 46px;
  font-weight: 500;
  line-height: 60px;
  text-align: center;
  color: #18181b;
  margin-bottom: 25px;

  @media screen and (min-width: 992px) {
    font-size: 52px;
    line-height: 60px;
  }
}

.text-offer {
  &.modal-para {
    text-align: center;
    color: #18181b;
    margin-bottom: 25px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;

    &:last-child {
      margin-bottom: 0;
    }

    @media screen and (min-width: 992px) {
      font-size: 22px;
      line-height: 34px;
    }
  }
}

.modal-body {
  padding: 0 53px 40px 53px;

  @media screen and (min-width: 992px) {
    padding: 32px 106px 80px 106px;
  }
}
