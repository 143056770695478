.slideshow {
  margin: 60px 0;
}

.slideshow-outer-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0 15px;
}

.slideshow-inner-wrapper {
  margin-top: 30px;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
  overflow-x: scroll;

  @media screen and (min-width: 992px) {
    overflow-x: hidden;
  }
}

.slideshow-product {
  width: 285px;
  height: 368px;
  border: 1px solid $base-color-gray;
  border-radius: 5px;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (min-width: 992px) {
    width: 397px;
    height: 514px;
  }
}

.slideshow-image {
  width: 285px;
  padding: 24px 38px 14px 38px;

  @media screen and (min-width: 992px) {
    width: 319px;
    padding: 51px 41px 45px 41px;
  }
}

.desc {
  &.slideshow-desc {
    padding: 0 26px;
    margin-bottom: 0;
    width: auto;

    @media screen and (min-width: 1200px) {
      white-space: nowrap;
    }
  }
}

.subtext {
  &.slideshow-subtext {
    margin-top: 4.87px;

    @media screen and (min-width: 992px) {
      margin: 14px 0 35px 0;
    }
  }
}
