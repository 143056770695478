//// CONTACT PAGE ////

.contact {
    @include straight-column;
    font-size: 1.5rem;
}

.contact-wrapper {
    display: grid;
    grid-template-rows: 1fr 400px;
    position: relative;
}

.contact-title {
    @include straight-column;
    font-size: 2.5em;
    font-weight: 900;
    font-family: $roboto-condensed;
    letter-spacing: 15px;
    position: absolute;
    color: $base-color-white;
    // top: 5%;
    // left: 0;

    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);

    // @include breakpoint-medium {
    //     // left: 5%;
    //     // margin-left: 20px;
    // }
}

.contact-image-wrapper {
    position: relative;
}

.image {
    height: 600px;
    width: 100%;
    object-fit: cover;

    @include breakpoint-medium {
        height: 100%;
        width: 100%;
    }
}

.contact-services {
  @include straight-column;
  margin-top: 60px;
  padding: 0 20px;
  gap: 100px;

  & .headline {
    transition: all .5s ease-in-out;
    &:hover {
       font-size: 1.5em; 
       cursor: pointer;
    }
  }

  & .headline-bold {
    transition: all .5s ease-in-out;
    &:hover {
       font-size: 2.5em; 
       cursor: pointer;
    }
  }
}

.social-wrapper {
    @include straight-row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 60px;
    padding-bottom: 40px;

    svg {
      &:hover {
            color: $base-red-color !important;
        }
    }
}

.services-wrapper {
  @include straight-column;
  margin-left: 40px;
  justify-content: space-between;
  gap: 20px;
}
  