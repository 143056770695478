//// WORK PAGE ////

.left-work-bar {
    width: 100%;
}

.right-work-bar {
    padding: 0 20px;
}
  
.work-title {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);

    z-index: 2;
    // margin: 0 0 0 15px;
    display: flex;
    font-weight: 900;
    justify-content: center;
    font-family: $roboto-condensed;
    font-size: 4.5em;
    color: $base-white-color;
    letter-spacing: 15px;
  }

.image-wrapper {
    height: 600px;
    position: relative;
}

.work-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.carousel-item-wrapper {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: gre;

    @include breakpoint-medium {
        margin-top: 20px;
    }
}

.card-desc {
    letter-spacing: 0.1em;
    text-align: center;
    margin: 20px 0;
    position: absolute;
    bottom: 5%;
    right: 5%;
    z-index: 1;

    & .headline-bold {
        color: $base-white-color;
        margin-left: 10px;
        text-shadow: rgb(0 0 0 / 10%) 0 0 20px;
        --h: 1.2em;
        line-height: var(--h);
        background: 
        linear-gradient(rgba(245,242,242,.5),rgba(245,242,242,.5)) bottom/100% calc(100% - var(--_i, 1)*100%) no-repeat;
        overflow: hidden;
        transition: .3s;

        &:hover {
            --_i: 0;
            color: $base-white-color;
        }
    }
}

.card-name-desc {
    font-size: 20px;
    color: $base-text-color;
}

.card {
    margin-top: 10px;
    width: auto;
    height: 250px;
    border: 1px solid whitesmoke;
    position: relative;
    
    img {
        height: 100%;
        width: 100%;
        background-color: #fff;
    }

    a {
        width: 100%;
        height: 100%;

        &:after {
            content: '';
            background-image: linear-gradient(
                rgba(0, 0, 0, 0.4),
                rgba(0, 0, 0, 0.4)
                );
                position: absolute;
                height: 100%;
                top: 0;
                left: 0;
                right: 0;
            }
    }

    @include breakpoint-large {
        height: 200px;
    }
}

.right-work-bar {
    & .swiper-pagination-bullet-active {
        background-color: $base-red-color;
    }

    & .swiper-wrapper {
        margin-bottom: 50px;
    }
}