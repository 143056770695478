@mixin animation-button() {
  width: 35px;
  margin: 0.5em 1em 0 0;
  font-weight: bold;

  &:hover {
    cursor: crosshair;
  }

  &:focus,
   input:focus {
    outline: none;
  }
}

@mixin desktop-animation-button() {
  width: 25px;
}

@mixin tablet-animation-button() {
  width: 15px;
}

@mixin flex-row() {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

@mixin straight-row() {
  display: flex;
  flex-direction: row;
}

@mixin straight-column() {
  display: flex;
  flex-direction: column;
}

@mixin text-focus() {
  @-webkit-keyframes text-focus-in {
    @content;
  }
  @keyframes text-focus-in {
    @content;
  }
}

@mixin jello-focus() {
  @-webkit-keyframes jello-horizontal {
    @content;
  }
  @keyframes jello-horizontal {
    @content;
  }
}

@mixin vibrate-focus() {
  @-webkit-keyframes vibrate-1 {
    @content;
  }
  @keyframes vibrate-1 {
    @content;
  }
}

////MEDIA QUERIES////

/* Grid breakpoints
 *
 * Define the minimum and maximum dimensions
 * where the layout will change, adapting to
 * different screen sizes, for use in media queries.
*/

$grid-breakpoints: (
  sm: 0,
  sm-max: 599px,
  md: 600px,
  md-max: 959px,
  lg: 960px,
  lg-max: 1259px,
  xl: 1260px
);

// Actual container widths
// $container-max-widths: (
//   md: 959px,
//   lg: 960px,
//   xl: 1260px
// );

// Breakpoint mins
$screen-sm-min: map-get($grid-breakpoints, sm);
$screen-md-min: map-get($grid-breakpoints, md);
$screen-lg-min: map-get($grid-breakpoints, lg);
$screen-xl-min: map-get($grid-breakpoints, xl);

// Breakpoint maxes
$screen-sm-max: map-get($grid-breakpoints, sm-max);
$screen-md-max: map-get($grid-breakpoints, md-max);
$screen-lg-max: map-get($grid-breakpoints, lg-max);

@mixin breakpoint-small {
  @media (min-width: $screen-sm-min) {
    @content;
  }
}

@mixin breakpoint-medium {
  @media (min-width: $screen-md-min) {
    @content;
  }
}

@mixin breakpoint-large {
  @media (min-width: $screen-lg-min) {
    @content;
  }
}

@mixin breakpoint-xlarge {
  @media (min-width: $screen-xl-min) {
    @content;
  }
}

// TO-DO: Need to depreciate these
@mixin bp($point) {
  @if $point == small-sz {
    @media (max-width: 700px) {
      @content;
    }
  } @else if $point == middle-sz {
    @media (max-width: 1250px) {
      @content;
    }
  } @else if $point == large-sz {
    @media (max-width: 1600px) {
      @content;
    }
  }
}
