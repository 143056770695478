////MENU STYLING////
.bm-menu-wrap {
    position: fixed;
    height: 100%;
    width: 100% !important;
    transition: none !important;
    right: 0 !important;
}

.bm-menu {
    padding: 2.5em 1.5em 2.5em;
    font-size: 1.15em;
    overflow: hidden;
}

.bm-burger-button {
    position: fixed;
    width: 60px;
    height: auto;
    left: 10px;
    top: 8px;

    & button {
      &:focus {
        outline: none;
      }
    }   
}

.react-icon.burger-icon:hover {
    color: rgb(240, 56, 15);;
}

.bm-cross-button {
    width: auto !important;
    height: auto !important;
    top: 10px !important;
    right: 16px !important;
}

/* WRAPPER FOR ITEM LIST */
.bm-item-list {
    @include straight-column;
    align-items: center;
    flex-direction: column;

    @include breakpoint-xlarge {
        flex-direction: row;
    }

    .bm-item {
        --h: 5.2em;
        line-height: var(--h);
        color: $base-color-red;
        // background: 
        // linear-gradient(
        //   rgba(0, 0, 0, 0.3),
        //   rgba(0, 0, 0, 0.3)
        // )
        // bottom/50% calc(50% - var(--_i,1)*100%) no-repeat;
        overflow: hidden;
        transition: 0.3s;
        font-family: LabGrotesque, Helvetica, Arial, sans-serif;
        flex: .5;
        text-align: center;
        width: 100%;
        letter-spacing: 15px;
        font-weight: 600;
        text-decoration: none;
        outline: none;
        position: relative;
        font-size: 1.5em;

        &:hover {
            --_i: 0;
            color: $base-white-color;
            ;
        }

        @include breakpoint-xlarge {
          --h: 1.2em;
          background: 
          linear-gradient(
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
          )
          bottom/100% calc(100% - var(--_i,1)*100%) no-repeat;
        }
    }
}

/* STYLING OF MENU OVERLAY */
.bm-overlay {
    opacity: 1;
    background: url('../../assets/images/deniz-demirci-unsplash.jpg') !important;
    background-repeat: no-repeat !important;
    background-position: left bottom !important;
    top: 0;
    left: 0;
    right: 0;
    background-color: whitesmoke;

    &:after {
      content: '';
      background-image: linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.2)
        );
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
    }
}

.bm-overlay .bm-burger-button {
    opacity: 0;
}